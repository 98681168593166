import React from 'react';
import unityLogo from './assets/unity-bnw.png';
import githubIcon from './assets/github.svg';
import linkedinIcon from './assets/linkedin.svg';
import instagramIcon from './assets/instagram.svg';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-desc">
          <div className="footer-logo">
            <img src={unityLogo} alt="UNITY Logo" />
          </div>
          <div className="footer-description">
            <h2>UNSRAT IT Community</h2>
            <p>
              UNITY adalah komunitas mahasiswa Teknik Informatika dari Universitas Sam Ratulangi yang dibentuk
              pada tanggal 23 April 2014
            </p>
          </div>
        </div>

        <div className="footer-links">
          <h2>Helpful Links</h2>
          <ul>
            <li><a href="#">Division</a></li>
            <li><a href="#">Techofest</a></li>
          </ul>
        </div>

        <div className="footer-contact">
          <h2>Contact Us:</h2>
          <a href="#">
            <img src={githubIcon} alt="GitHub" width="16" height="16" />
          </a>
          <a href="#">
            <img src={linkedinIcon} alt="LinkedIn" width="16" height="16" />
          </a>
          <a href="#">
            <img src={instagramIcon} alt="Instagram" width="16" height="16" />
          </a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© Copyright UNSRAT IT Community All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;