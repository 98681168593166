import React from 'react';
import Title from './general-components/title';
import CharaBox from './select-your-chara-components/chara-box';
import maleChara from './assets/male-chara.png';
import femaleChara from './assets/female-chara.png';

const SelectYourChara = () => {
  return (
    <section id="select-your-chara">
      <Title name="Pilih Karaktermu" />
      <div className="chara-container">
        <CharaBox
          charaType="male"
          name="Chamber"
          imgSrc={maleChara}
          altText="Chamber"
          desc="There's a lot to learn about Techoverse, so we'll start with the essentials. Explore the guide below to get the rundown on the most popular game mode."
        />
        <CharaBox
          charaType="female"
          name="Reyna"
          imgSrc={femaleChara}
          altText="Reyna"
          desc="There's a lot to learn about Techoverse, so we'll start with the essentials. Explore the guide below to get the rundown on the most popular game mode."
        />
      </div>
    </section>
  );
};

export default SelectYourChara;