import React from 'react';
import background from './assets/background-hero.jpg';

const Hero = () => {
  return (
    <section id="hero">
      <div className="container">
        <div className="container-hero">
          <h1>Selamat Datang di, <span>Techoverse</span></h1>
          <p>UNITY adalah komunitas mahasiswa Teknik Informatika dari Universitas Sam Ratulangi yang dibentuk pada tanggal
            23 April 2014, atas gagasan dari para dosen pengajar kami dalam program studi Teknik Informatika.</p>
          <button className="explore">Jelajahi</button>
          <img src={background} alt="Techoverse" />
        </div>
      </div>
    </section>
  );
};

export default Hero;