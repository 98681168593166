import React from "react";
import Navbar from "./navbar";
import Hero from "./hero";
import Basics from "./basics";
import AboutTechoverse from "./about-techoverse";
import SelectYourChara from "./select-your-chara";
import JoinTheVerse from "./join-the-verse";
import Footer from "./footer";

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Basics />
      <AboutTechoverse />
      <SelectYourChara />
      <JoinTheVerse />
      <Footer />
    </div>
  );
}

export default App;