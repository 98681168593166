import React from 'react';
import line from '../assets/Line.png';
import star from '../assets/star.png';

const Title = (props) => {
  return (
    <div className="title">
      <div className="line">
        <img src={line} alt="Line" />
      </div>
      <h1>{props.name}</h1>
      <div className="star">
        <img src={star} alt="Star" />
      </div>
    </div>
  );
};

export default Title;