import React from 'react';
import Card from './about-techoverse-compenent/card'; 
import xIcon from './assets/X.png';
import oIcon from './assets/O.png';
import triangleIcon from './assets/triangle.png';

const AboutTechoverse = () => {
  return (
    <section id="about-techoverse">
      <div className="container-about">
        <Card
          title="Tentang"
          content="UNITY adalah komunitas mahasiswa Teknik Informatika dari Universitas Sam Ratulangi yang dibentuk pada tanggal 23 April 2014, atas gagasan dari para dosen pengajar kami dalam program studi Teknik Informatika."
          icon={xIcon}
          iconAlt="X Icon"
          iconClassName="icon"
        />
        <Card
          title="Tentang"
          content="UNITY adalah komunitas mahasiswa Teknik Informatika dari Universitas Sam Ratulangi yang dibentuk pada tanggal 23 April 2014, atas gagasan dari para dosen pengajar kami dalam program studi Teknik Informatika."
          icon={oIcon}
          iconAlt="O Icon"
          iconClassName="icon-left"
        />
        <Card
          title="Tentang"
          content="UNITY adalah komunitas mahasiswa Teknik Informatika dari Universitas Sam Ratulangi yang dibentuk pada tanggal 23 April 2014, atas gagasan dari para dosen pengajar kami dalam program studi Teknik Informatika."
          icon={triangleIcon}
          iconAlt="Triangle Icon"
          iconClassName="icon"
        />
      </div>
    </section>
  );
};

export default AboutTechoverse;