import React from 'react';
import Moon from '../assets/moon.png';

const Card = (props) => {
  return (
    <div className="card">
      <div className="content">
        <h1>{props.title} <span>Techoverse ✦</span></h1>
        <p>{props.content}</p>
      </div>
      {/* Apply the custom className here */}
      <img src={props.icon} alt={props.iconAlt} className={props.iconClassName} />
      <img src={Moon} alt="Moon Icon" className="moon top-left" />
      <img src={Moon} alt="Moon Icon" className="moon top-right" />
      <img src={Moon} alt="Moon Icon" className="moon bottom-left" />
      <img src={Moon} alt="Moon Icon" className="moon bottom-right" />
    </div>
  );
};

export default Card;
