import React from "react";
import unityLogo from './assets/unity-logo.png';
import techoverseLogo from './assets/techoverse-logo.png'

const Navbar = () => {
  return (
    <div className="navbar">
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 box-header">
        <div className="unity-logo">
          <a
            href="#"
            className="d-inline-flex link-body-emphasis text-decoration-none"
          >
            <img src={unityLogo} alt="Unity Logo" />
          </a>
        </div>
        <div className="techoverse-logo">
            <img src={techoverseLogo} alt="Techoverse Logo" />
        </div>

        <ul className="nav col-12 col-md-auto mb-2 justify-content-center">
          <li>
            <a href="#" className="nav-link px-2 active">
              Beranda
            </a>
          </li>
          <li>
            <a href="#" className="nav-link px-2">
              Catatan Patch
            </a>
          </li>
          <li>
            <a href="#" className="nav-link px-2">
              Galeri
            </a>
          </li>
          <li>
            <a href="#" className="nav-link px-2">
              Tentang Kami
            </a>
          </li>
        </ul>

        <div className=" play-button">
          <button type="button">Mainkan</button>
        </div>
      </header>
    </div>
  );
};

export default Navbar;