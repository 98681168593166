import React from 'react';
import basicsBg from './assets/basics-bg.jpg';
import bubbles from './assets/bubble-grey.png';
import Title from './general-components/title';

const Basics = () => {
  return (
    <section id="basics">
      <Title name="Dasar"/>
      <div className="basics-container">
        <div className="basics-bg">
          <img className="basics-bg" src={basicsBg} alt="Basics Background" />
        </div>
        <p className="welcome">Selamat Datang di Techoverse</p>
        <h1>PELAJARI DASARNYA</h1>
        <p className="text">
          There’s a lot to learn about Techoverse, so we’ll start with the essentials. Explore the guide below to
          get the rundown on the most popular game mode.
        </p>
        <div className="bubble">
          <img src={bubbles} className="one" alt="Bubble Grey 1" />
          <img src={bubbles} className="two" alt="Bubble Grey 2" />
          <img src={bubbles} className="three" alt="Bubble Grey 3" />
          <img src={bubbles} className="four" alt="Bubble Grey 4" />
          <img src={bubbles} className="five" alt="Bubble Grey 5" />
          <img src={bubbles} className="six" alt="Bubble Grey 6" />
          <img src={bubbles} className="seven" alt="Bubble Grey 7" />
        </div>
      </div>
    </section>
  );
};

export default Basics;