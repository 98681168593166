import React from 'react';

const CharaBox = (props) => {
  return (
    <div className={`chara-box ${props.charaType}`}>
      <h3>{props.name}</h3>
      <img src={props.imgSrc} alt={props.altText} className={props.charaType} />
      <p>{props.desc}</p>
    </div>
  );
};

export default CharaBox;
